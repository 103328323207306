import { useMutation } from '@apollo/client';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js/pure';
import gql from 'graphql-tag';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      whiteSpace: 'pre-line',
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    pricesContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    totalContainer: {
      maxWidth: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
);

interface Props {
  track: any;
  currentUser: any;
}

const CHECKOUT_CREATION = gql`
  mutation CreateCheckout($input: TrackCheckoutInput!) {
    checkout: trackPriceCheckout(input: $input) {
      sessionId
      accountId
      stripeKey
    }
  }
`;

function formatRecurringDisplay(price: any) {
  return price.replace('/yr', ' per year').replace('/mo', ' per month');
}

function calculateDiscount(price: any, recurringPrices: any) {
  const monthPlan = recurringPrices.find((p) => p.recurringMonths === 1);
  const months = price.recurringMonths;

  const save = months * monthPlan.amountCents - price.amountCents;
  if (save % 100 === 0) {
    return `$${Math.floor(save / 100)}`;
  } else {
    return `$${(save / 100).toFixed(2)}`;
  }
}

const PaymentStep = ({ track, currentUser }: Props) => {
  const styles = useStyles();

  const [currentPrice, setCurrentPrice] = useState(track.activePrice);
  const isBundle = !!track.activePrice.bundle;
  const bundlePrices = isBundle ? track.activePrice.bundle.prices : [];
  const discounts = isBundle ? track.activePrice.bundle.discounts : [];
  const activePrices = track.activePrices || [];

  const [stripeRedirecting, setStripeRedirecting] = useState(false);
  const [selectedBundlePriceIds, setSelectedBundlePriceIds] = useState(
    bundlePrices.map((p) => p.id)
  );
  const [createCheckout] = useMutation(CHECKOUT_CREATION, {});

  const appliedDiscountPrice =
    discounts.length > 0
      ? discounts.find((d) => d.min === selectedBundlePriceIds.length)
      : null;
  const appliedDiscount = appliedDiscountPrice
    ? appliedDiscountPrice.amountCents
    : 0;
  const subtotal = bundlePrices
    .filter((p) => selectedBundlePriceIds.includes(p.id))
    .reduce((acc, p) => acc + p.amountCents, 0);

  const checkout = () => {
    setStripeRedirecting(true);
    const url = new URL(window.location.href);
    const cancelUrl = url.toString();
    url.searchParams.set('stripeSuccess', 'true');
    const successUrl = url.toString();
    createCheckout({
      variables: {
        input: {
          priceId: currentPrice.id,
          bundlePriceIds: selectedBundlePriceIds,
          successUrl,
          cancelUrl,
        },
      },
    }).then(({ data }) => {
      if (data) {
        loadStripe(data.checkout.stripeKey, {
          stripeAccount: data.checkout.accountId,
        }).then((stripe) => {
          stripe.redirectToCheckout({ sessionId: data.checkout.sessionId });
        });
      }
    });
  };

  return (
    <div>
      <Box className={styles.instructions}>
        {currentUser ? (
          <>
            Hi <strong>{currentUser.name}</strong>{' '}
            <em> ({currentUser.c2Username})</em>, we{' '}
          </>
        ) : (
          'We '
        )}
        integrate with Stripe: one of the most secure payment platforms.
      </Box>
      <Box className={styles.instructions}>
        {isBundle
          ? 'Select one or more competitions you want to participate:'
          : activePrices.length > 1
          ? 'Choose your payment option and then press the button below to pay with Apple Pay, Google Pay or credit card.'
          : 'Press the button below to pay with Apple Pay, Google Pay or credit card.'}
      </Box>
      {bundlePrices.length > 0 && (
        <>
          <Box className={styles.pricesContainer}>
            <List>
              {bundlePrices.map((price) => {
                const labelId = `option-${price.id}`;

                return (
                  <ListItem
                    key={price.id}
                    role={undefined}
                    dense
                    button
                    onClick={() =>
                      setSelectedBundlePriceIds((ids) =>
                        ids.includes(price.id)
                          ? ids.filter((id) => id !== price.id)
                          : [...ids, price.id]
                      )
                    }
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedBundlePriceIds.includes(price.id)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={price.name}
                      secondary={price.displayText}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <List className={styles.totalContainer}>
            <ListItem>
              <ListItemIcon>
                <strong>Subtotal</strong>
              </ListItemIcon>
              <ListItemText
                style={{ textAlign: 'end' }}
                primary={`$${Math.floor(subtotal / 100)}`}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <strong>Discount</strong>
              </ListItemIcon>
              <ListItemText
                style={{ textAlign: 'end' }}
                primary={
                  appliedDiscount
                    ? `-$${Math.floor(appliedDiscount / 100)}`
                    : '-'
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <strong>Total</strong>
              </ListItemIcon>
              <ListItemText
                style={{ textAlign: 'end' }}
                primary={`$${Math.floor((subtotal - appliedDiscount) / 100)}`}
              />
            </ListItem>
          </List>
        </>
      )}
      {activePrices.length > 1 && (
        <>
          <Box className={styles.pricesContainer}>
            <List>
              {activePrices.map((price) => {
                const labelId = `option-${price.id}`;

                return (
                  <ListItem
                    key={price.id}
                    role={undefined}
                    dense
                    button
                    onClick={() => setCurrentPrice(price)}
                  >
                    <ListItemIcon>
                      <Radio
                        edge="start"
                        checked={price.id === currentPrice.id}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={formatRecurringDisplay(price.displayText)}
                      secondary={
                        price.recurringMonths > 1
                          ? `Save ${calculateDiscount(price, activePrices)}`
                          : price.name
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </>
      )}
      <Button
        className={styles.button}
        variant="contained"
        color="primary"
        disabled={
          stripeRedirecting || (isBundle && selectedBundlePriceIds.length === 0)
        }
        onClick={() => checkout()}
      >
        Stripe Checkout
      </Button>
    </div>
  );
};

export default PaymentStep;
