import { useQuery } from '@apollo/client';
import { Breadcrumbs } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Link } from 'gatsby';
import gql from 'graphql-tag';
import React, { useEffect, useMemo, useState } from 'react';
import Linkify from 'react-linkify';
import { StringParam, useQueryParam } from 'use-query-params';
import useCurrentUser from '../hooks/useCurrentUser';
import Header from './BasicHeader';
import Footer from './Footer';
import Layout from './Layout';
import DivisionStep from './track/DivisionStep';
import PaymentStep from './track/PaymentStep';
import { isMobile } from './styles';
const unified = require('unified');
const markdown = require('remark-parse');
const html = require('remark-html');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    avatar: {
      marginBottom: theme.spacing(1),
      width: '3rem',
      height: '3rem',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      whiteSpace: 'pre-line',
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    accept: {
      padding: theme.spacing(0.5),
    },
    descriptionContainer: {
      textAlign: 'left',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.grey[100],
    },
    badgeContainer: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      height: 26,
      borderRadius: 13,
      color: 'white',
      backgroundColor: theme.palette.grey[600],
    },
    openApp: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
        display: 'block',
      },
    },
  })
);

const GET_TRACK = gql`
  query GetTrack($id: ID!) {
    track(id: $id) {
      id
      compRegistration {
        id
        gender
        ageGroup {
          name
        }
        teamDescription
        weightGroupOptions {
          name
          value
          details
        }
        divisionOptions {
          name
          value
          details
        }
      }
    }
  }
`;

const STEPS = {
  login: 'Login',
  division: 'Division',
  payment: 'Payment',
  confirmation: 'Confirmation',
};
const COMP_STEPS = ['login', 'division', 'payment', 'confirmation'];
const REGULAR_STEPS = ['login', 'payment', 'confirmation'];

const TrackPage = ({ pageContext }) => {
  const { track } = pageContext;
  const description = useMemo(() => {
    if (pageContext.description) return pageContext.description;
    const content = !!track.registrationDescription
      ? unified()
          .use(markdown)
          .use(html)
          .processSync(track.registrationDescription)
          .toString()
      : null;

    return content;
  }, []);
  const price = track.activePrice;
  const activePrices = track.activePrices || [];
  const hasRecurringPrices = activePrices.every((p) => p.recurringMonths);

  const [stripeSuccess] = useQueryParam('stripeSuccess', StringParam);
  const [tokenParam, setTokenParam] = useQueryParam('token', StringParam);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { currentUser, redirectToAuth, savePersistedToken } = useCurrentUser();

  const { data, refetch } = useQuery(GET_TRACK, {
    variables: { id: track.id },
    skip: !currentUser,
  });

  const membership =
    currentUser &&
    currentUser.trackMemberships.find(
      (tm) => tm.track.id === track.id && tm.active && !tm.trial
    );

  const isGrantedMembership =
    membership &&
    !!membership.recurring &&
    membership.status === 'access-granted';

  const styles = useStyles();
  const [activeStep, setActiveStep] = useState('login');

  useEffect(() => {
    if (tokenParam) {
      setTokenParam(undefined, 'replaceIn');
      savePersistedToken(tokenParam);
    }
  }, [tokenParam]);

  const steps = track.compStatus ? COMP_STEPS : REGULAR_STEPS;

  const missingCompRegistration =
    track.compStatus && data && !data.track.compRegistration.id;
  const teamDescription =
    track.compStatus && data && data.track.compRegistration.teamDescription;

  useEffect(() => {
    if (missingCompRegistration) {
      setActiveStep('division');
    } else if (
      (membership && !isGrantedMembership) ||
      stripeSuccess === 'true'
    ) {
      setActiveStep('confirmation');
    } else if (currentUser) {
      setActiveStep('payment');
    }
  }, [
    currentUser,
    setActiveStep,
    stripeSuccess,
    membership,
    missingCompRegistration,
  ]);

  const allowRegistration = [
    undefined,
    null,
    'unlisted',
    'registration',
  ].includes(track.compStatus);

  const activeStepIndex = steps.indexOf(activeStep);

  return (
    <Layout
      titlePrefixes={[
        track.name,
        track.planWeeks
          ? 'Training Plan'
          : track.compStatus
          ? 'Competition'
          : hasRecurringPrices
          ? 'Programming'
          : null,
        track.source.name,
      ]}
    >
      <Header />

      <section>
        <div className="container">
          <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
            <Link color="inherit" to="/discover">
              Discover
            </Link>
            <Link color="inherit" to={`/discover?source=${track.source.slug}`}>
              {track.source.name}
            </Link>
          </Breadcrumbs>
          <div className="text-center section-heading">
            <Box
              flexDirection="column"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar className={styles.avatar} src={track.imageUrl} />
              <Typography variant="h4">
                {activePrices.length > 1 ? track.name : price.name}
              </Typography>
              <Box display="flex">
                <Typography
                  variant="subtitle2"
                  className={styles.badgeContainer}
                >
                  {activePrices.length > 1
                    ? `${activePrices[0].displayText} - ${
                        activePrices[activePrices.length - 1].displayText
                      }`
                    : price.displayText}
                </Typography>
                {!!track.compDateDisplay &&
                  !['in_progress', 'done'].includes(track.compStatus) && (
                    <Typography
                      variant="subtitle2"
                      className={styles.badgeContainer}
                    >
                      {track.compDateDisplay}
                    </Typography>
                  )}
              </Box>
            </Box>
            <div className={styles.root}>
              {allowRegistration ? (
                <>
                  <Stepper activeStep={activeStepIndex} alternativeLabel>
                    {steps.map((label, i) => (
                      <Step
                        key={label}
                        completed={
                          i < activeStepIndex ||
                          (activeStepIndex === steps.length - 1 &&
                            i === activeStepIndex)
                        }
                      >
                        <StepLabel>{STEPS[label]}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <div>
                    {activeStep === 'login' && (
                      <div>
                        <Box>
                          <FormControlLabel
                            className={styles.accept}
                            control={
                              <Checkbox
                                checked={termsAccepted}
                                onChange={() =>
                                  setTermsAccepted((value) => !value)
                                }
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label={
                              <Typography variant="body2" align="left">
                                I have read and accept ErgZone's{' '}
                                <Link to="/privacy">privacy policy</Link> and{' '}
                                <Link to="/terms">terms</Link>.
                              </Typography>
                            }
                          />
                        </Box>
                        <Button
                          className={styles.button}
                          disabled={!termsAccepted}
                          variant="contained"
                          color="primary"
                          onClick={() => redirectToAuth()}
                        >
                          Login
                        </Button>
                      </div>
                    )}
                    {activeStep === 'division' && (
                      <DivisionStep
                        track={track}
                        compRegistration={data.track.compRegistration}
                        currentUser={currentUser}
                        reload={refetch}
                      />
                    )}
                    {activeStep === 'payment' && (
                      <>
                        {isGrantedMembership && (
                          <Alert severity="info">
                            <AlertTitle>
                              You already have access to{' '}
                              <strong>{track.name}</strong>. If you want to
                              start paying through ErgZone, please continue with
                              the payment process.
                            </AlertTitle>
                          </Alert>
                        )}
                        <PaymentStep track={track} currentUser={currentUser} />
                      </>
                    )}
                    {activeStep === 'confirmation' && (
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Alert severity="success">
                            <AlertTitle>
                              Congratulations
                              {currentUser ? ` ${currentUser.name}` : ''}. You
                              are now signed up for{' '}
                              <strong>{track.name}</strong>!
                            </AlertTitle>
                            Open the ErgZone app{' '}
                            {track.compStatus
                              ? `to see the comp details${
                                  teamDescription
                                    ? ' and create or join a team'
                                    : ''
                                }.`
                              : 'to see the new workouts.'}
                          </Alert>
                          {isMobile() && (
                            <Button
                              className={styles.openApp}
                              color="primary"
                              href="https://app.erg.zone/"
                              variant="contained"
                            >
                              Open app
                            </Button>
                          )}
                        </Box>
                        {membership && !!membership.description && (
                          <Box className={styles.descriptionContainer}>
                            <Typography
                              variant="h5"
                              className={styles.instructions}
                            >
                              More Information
                            </Typography>
                            <Box className={styles.instructions}>
                              <Linkify>{membership.description}</Linkify>
                            </Box>
                          </Box>
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  marginTop={2}
                >
                  <Alert severity="warning">Registration is closed!</Alert>
                  <Button
                    style={{ marginTop: 10 }}
                    color="primary"
                    href={`https://comp.erg.zone/leaderboard/${track.idToken}`}
                    variant="contained"
                  >
                    Leaderboard
                  </Button>
                </Box>
              )}
              {!!description ? (
                <Box
                  className={`${styles.descriptionContainer} markdownContainer`}
                >
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </Box>
              ) : !!track.description ? (
                <Box className={styles.descriptionContainer}>
                  <h4>Description</h4>
                  <Box className={styles.instructions}>
                    <Linkify>{track.description}</Linkify>
                  </Box>
                </Box>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default TrackPage;
